import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/26120-startup-project.json'

const Rocket = () => {
  return (
    <Lottie
      animationData={lottieJson}
    />
  )
}

export default Rocket;