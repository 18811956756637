import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ThreeLamps = ({ style }) => {
    const data = useStaticQuery(graphql`
    query {
        threeLamps: file(relativePath: { eq: "academy/three_lamps.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style={style}
        fluid={data.threeLamps.childImageSharp.fluid} alt="Three lamps"
    />
}

export default ThreeLamps