import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/33937-the-matryoshka-effect-animated-charts-and-graphs-no-3c.json'

const Circles = () => {
  return (
    <Lottie
      animationData={lottieJson}
    />
  )
}

export default Circles;