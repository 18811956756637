import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Chess = ({ style }) => {
    const data = useStaticQuery(graphql`
    query {
        chess: file(relativePath: { eq: "academy/chess.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style={style}
        fluid={data.chess.childImageSharp.fluid} alt="Man pushing pieces of chess"
    />
}

export default Chess