import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const WorldMap = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      worldMap: file(relativePath: { eq: "academy/world_map.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  const imageData = data.worldMap.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        minHeight: `100vh`
      }}
    >
      <div>
        {children}
      </div>
    </BackgroundImage>
  )
}

export default WorldMap