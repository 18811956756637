import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const GraduateCaps = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      graduateCaps: file(relativePath: { eq: "academy/graduate_caps.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.graduateCaps.childImageSharp.fluid} alt="Graduate caps in the air"
  />
}

export default GraduateCaps