import React from "react"
import Layout from "../components/layout"
import Menu from "../components/menu"
import Styled from "styled-components"
import VerticalTitle from "../components/verticaltitle"
import Body from "../components/body"
import { Row, Col } from "react-bootstrap"
import Footer from "../components/footer"
import Highlight from "../components/highlight"
import Curve from "../components/academy-images/curve"
import Rocket from "../components/lottie-components/rocket"
import PersonStudying from "../components/academy-images/person_studying"
import Circles from "../components/lottie-components/circles"
import GraduateCaps from "../components/academy-images/graduate_caps"
import KeyboardNotebook from "../components/academy-images/keyboard_notebook"
import ThreeLamps from "../components/academy-images/three_lamps"
import PaleGeometric from "../components/lottie-components/pale_geometric"
import Chess from "../components/academy-images/chess"
import Path from "../components/academy-images/path"
import WorldMap from "../components/academy-images/world_map"
import WorkMeeting from "../components/academy-images/work_meeting"
import GeometricShapes from "../components/lottie-components/geometric_shapes"
import Form from "../components/form"
import LazyLoad from "react-lazyload"

const LampsContainer = Styled.div`
position: absolute;
width: 20em;
margin-top: -8em;
z-index: 0;
@media(min-width: 900px){
  margin-left: 30vw;
}
`

const IndexPage = () => (
  <Layout style={{ backgroundColor: `black`, color: `#ddd` }}
    lang="en" title="HAG Academy" link="https://www.hag.academy" description="HAG Academy aims to create change throughout education by focusing on transformational programs that build soft skills, trust and confidence throughout PBL.">
    <Menu
      MenuItem4={
        <Body>
          <Highlight>
            <Curve>
              <Row>
                <Col sm="12" md="2">
                  <VerticalTitle style={{ color: `white` }}>
                    academy
                </VerticalTitle>
                </Col>
                <Col sm="12" md="10" style={{ padding: `10vw` }}>
                  <p>With so much <span className="text-highlight-yellow">rapid development</span> and innovation in the world today, many of the traditional modes of thinking and doing business are going out of vogue.</p>
                  <p>To stay ahead of the <b>curve</b>, today’s generation of professionals and business owners must have a mindset that is geared towards the future.</p>
                </Col>
              </Row>
            </Curve>
            <Row className="align-items-center" style={{ padding: `10vw` }}>
              <Col sm="12" md="6">
                <p>This means investing in programs that utilise updated, world-class methodologies in education and <span className="text-highlight-yellow">entrepreneurship.</span></p>
              </Col>
              <Col sm="12" md="6">
                <LazyLoad offset={100} height={`50vh`}>
                  <Rocket />
                </LazyLoad>
              </Col>
            </Row>
            <PersonStudying>
              <div style={{ padding: `10vw` }}>
                <h2><b>WHY LEARN WITH HAG?</b></h2>
                <p>At HAG Academy, we nurture disruptive mindsets that put value in transformational leadership and view risks as enablers rather than as barriers.
                We believe that <b>education and transformation</b> are critical ingredients for success, which is why we develop disruptive ideas through <b>entrepreneurial education.</b></p>
                <p>Here’s how HAG Academy can set you up for success.</p>
              </div>
            </PersonStudying>
            <div style={{ backgroundColor: `#485696`, minHeight: `100vh`, padding: `10vw` }}>
              <h2><b>WE TEACH SELF-RELIANCE.</b></h2>
              <p>HAG offers proprietary educational programs that are based on experiential learning.</p>
              <Row className="align-items-center">
                <Col sm="12" md="6">
                  <p>Our aim is to develop qualities like proactiveness and autonomy, as well as to instil a <span className="text-highlight-black">problem-solver approach</span> to challenges.</p>
                </Col>
                <Col sm="12" md="6">
                  <LazyLoad offset={100} height={`50vh`}>
                    <Circles />
                  </LazyLoad>
                </Col>
              </Row>
            </div>
            <div style={{ padding: `10vw` }}>
              <p>Our <b>graduates</b> leave the Academy with the ability to take control of challenging situations and the motivation to become the <span className="text-highlight-yellow">protagonists</span> in their own personal and professional lives.</p>
              <Row>
                <Col sm="12" md={{ span: 8, offset: 4 }}>
                  <GraduateCaps />
                </Col>
              </Row>
            </div>
            {/* COloquei o z index para os fios das lampadas não sobreporem a imagem */}
            <div style={{ position: `relative`, zIndex: `1` }}>
              <KeyboardNotebook>
                <div style={{ padding: `10vw` }}>
                  <h2><b>WE FOCUS ON YOUR SPECIFIC NEEDS.</b></h2>
                  <p>As a dynamic company that puts a premium on uniqueness and innovation, we customise our programs to suit the needs of your organisation, school or business.</p>
                  <p>This ensures that, together, we meet the goals that you have in mind for yourself and your team. We offer short courses, lectures, workshops and training.</p>
                </div>
              </KeyboardNotebook>
            </div>
            <div style={{ backgroundColor: `#485696`, padding: `10vw` }}>
              <LampsContainer>
                <ThreeLamps />
              </LampsContainer>
              <div style={{ position: `relative`, zIndex: `1` }}>
                <h2><b>WE KEEP OUR PROGRAMS FRESH AND RELEVANT.</b></h2>
                <p>As part of our agile methodologies, we frequently collaborate with third parties to infuse fresh insights and add value to our programs.</p>
                <p>Besides experiential learning, we also incorporate design thinking to promote a <span className="text-highlight-black">user-centred and solutions-based approach.</span></p>
              </div>
            </div>
            <div style={{ padding: `10vw` }}>
              <Row className="align-items-center">
                <Col sm="12" md="6">
                  <p>We likewise integrate the lean <span className="text-highlight-yellow">startups</span>, disciplined entrepreneurship and <b>venture building</b> approach, among other methodologies, into our programs to help those who are planning to create and manage their own <b>startup</b> or to redesign their  own businesses.</p>
                </Col>
                <Col sm="12" md="6">
                  <LazyLoad offset={100}>
                    <PaleGeometric />
                  </LazyLoad>
                </Col>
              </Row>
            </div>
            <div style={{ paddingRight: `10vw`, paddingLeft: `10vw` }}>
              <h2><b>WE CATER TO EVERYONE WHO WANTS TO LEVEL UP THEIR SKILLS.</b></h2>
              <p>Whether you are an executive, professional, <b>entrepreneur</b> or a student who is looking for <b>reskilling and upskilling</b> opportunities, our programs are designed to equip you with the necessary skills to become a prime <span className="text-highlight-yellow">changer</span> in the world today.</p>
            </div>
            <Row>
              <Col sm="12" md={{ offset: 6, span: 6 }}>
                <Chess />
              </Col>
            </Row>
            <Path>
              <Row style={{ padding: `10vw` }}>
                <Col sm="12" md="6">
                  <p>With our <span className="text-highlight-yellow">disruptive philosophies</span>, we can help you discover a new sense of personal or professional purpose, strengthen the skills and nurture the values necessary for success, and guide you in your search for <b>new paths.</b></p>
                </Col>
              </Row>
            </Path>
            <WorldMap>
              <div style={{ padding: `10vw` }}>
                <h2><b>WE’RE EVERYWHERE.</b></h2>
                <p>We have been actively contributing as mentors or experts to well known international programs, including the European Innovation Academy, Global Scaling Academy, Founder Institute, Arab Innovation Academy, Alchemist, Startup Chile and more.</p>
              </div>
            </WorldMap>
            <WorkMeeting>
              <div style={{ padding: `10vw` }}>
                <p>We have also designed and launched numerous successful proprietary programs or in partnership with others like The Business Change, HAG Studio, ANGRAD, Hotmilk, among others.</p>
                <p>HAG Academy is present in Latin America, the USA, Europe, Asia and the Middle East.</p>
              </div>
            </WorkMeeting>
            <div style={{ padding: `10vw` }}>
              <h2><b>LEARN WITH US</b></h2>
              <p>With HAG Academy, you’ll experience a <b>transformationin</b> your approach to challenges and opportunities with our world-class <b>mentorship</b> programs.</p>
              <p>Let us be a part of your <span className="text-highlight-yellow">journey</span> towards <b>life-long learning.</b> Learn with us today.</p>
              <LazyLoad offset={100} height={`20vh`}>
                <GeometricShapes />
              </LazyLoad>
            </div>
            <Form />
            <Footer />
          </Highlight>
        </Body>
      }
      Titles={["about", "services", "studio"]}
      Links={["https://www.hag.ventures", "https://www.hag.ventures/about", "https://www.hag.services", "https://www.hag.studio"]}
    ></Menu>
  </Layout >
)

export default IndexPage