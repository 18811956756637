import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/31080-background-11.json'

const PaleGeometric = () => {
  return (
    <Lottie
      animationData={lottieJson}
      style={{ width: 300 }}
    />
  )
}

export default PaleGeometric